import React from 'react';
import './App.css';

import Monogram from './images/monogram.webp';
import ComingSoon from './components/ComingSoon/ComingSoon';

export default function App() {
  const isComingSoon = true;

  return (
    <div className="App">
      <img src={Monogram} alt="Coming Soon" className='monogram' loading='lazy'/>
      {
        isComingSoon ? <ComingSoon /> : (
          <header className="App-header">
            <p>
              Edit <code>src/App.js</code> and save to reload.
            </p>
            <a
              className="App-link"
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn React
            </a>
          </header>
        )
      }
    </div>
  );
}
