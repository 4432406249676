import React from 'react';
import './ComingSoon.css';

import Logo from '../../images/logo.webp';
import InstagramQR from '../../images/instagramQR.jpg';

export default function ComingSoon() {

  return (
    <main className="main maxWidth">
      <div className="content">
        <img src={Logo} alt="Logo" className="logo" loading="lazy" />
        <h1 className="title">Coming Soon...</h1>
        <a
          href="https://www.instagram.com/padel.alley"
          className="instagramContainer"
        >
          <h3>Contact us on Instagram:</h3>
          <img
            src={InstagramQR}
            alt="Instagram"
            className="instagramLogo"
            loading="lazy"
          />
          <p>@padel.alley</p>
        </a>
      </div>
    </main>
  );
}
